import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
		meta: {
			title: '登录'
		},
		path: '/log',
		name: 'log',
		component: () => import('../views/LOG/log.vue')
	},

	{
		meta: {
			title: '首页'
		},
		path: '/',
		name: 'index',
		component: () => import('../views/index.vue'),
		children: [{
				meta: {
					title: '会员列表'
				},
				path: '/memberlist',
				name: 'memberlist',
				component: () => import('../views/MEMBER/member-list.vue')
			},
			{
				meta: {
					title: '预约记录'
				},
				path: '/orderlist',
				name: 'orderlist',
				component: () => import('../views/MEMBER/order-list.vue')
			},
			{
				meta: {
					title: '就诊人'
				},
				path: '/memberlistman',
				name: 'memberlistman',
				component: () => import('../views/MEMBER/member-list-man.vue')
			},
			{
				meta: {
					title: '医生列表'
				},
				path: '/doctorlist',
				name: 'doctorlist',
				component: () => import('../views/DOCTOR/doctor-list.vue')
			},
			{
				meta: {
					title: '添加医生'
				},
				path: '/doctoradd',
				name: 'doctoradd',
				component: () => import('../views/DOCTOR/doctor-add.vue')
			},
			{
				meta: {
					title: '科室列表'
				},
				path: '/departmentlist',
				name: 'departmentlist',
				component: () => import('../views/DEPARTMENT/department-list.vue')
			},
			{
				meta: {
					title: '添加科室'
				},
				path: '/departmentadd',
				name: 'departmentadd',
				component: () => import('../views/DEPARTMENT/department-add.vue')
			},
			{
				meta: {
					title: '查看排班'
				},
				path: '/departmentsche',
				name: 'departmentsche',
				component: () => import('../views/DEPARTMENT/department-scheduling.vue')
			},
			{
				meta: {
					title: '文章列表'
				},
				path: '/newslist',
				name: 'newslist',
				component: () => import('../views/NEWS/news-list.vue')
			},
			{
				meta: {
					title: '添加文章'
				},
				path: '/newsadd',
				name: 'newsadd',
				component: () => import('../views/NEWS/news-add.vue')
			},
			{
				meta: {
					title: '回收站'
				},
				path: '/newsoutlist',
				name: 'newsoutlist',
				component: () => import('../views/NEWS/news-outlist.vue')
			},
			{
				meta: {
					title: '文章分类列表'
				},
				path: '/newsmenulist',
				name: 'newsmenulist',
				component: () => import('../views/NEWS_MENU/newsmenu-list.vue')
			},
			{
				meta: {
					title: '文章添加分类'
				},
				path: '/newsmenuadd',
				name: 'newsmenuadd',
				component: () => import('../views/NEWS_MENU/newsmenu-add.vue')
			},

			{
				meta: {
					title: '数据库备份'
				},
				path: '/sqlbackuo',
				name: 'sqlbackuo',
				component: () => import('../views/SQL/sql-backup.vue')
			},

			{
				meta: {
					title: '数据库还原'
				},
				path: '/sqlreduction',
				name: 'sqlreduction',
				component: () => import('../views/SQL/sql-reduction.vue')
			},
			{
				meta: {
					title: '管理员列表'
				},
				path: '/rootadmin',
				name: 'rootadmin',
				component: () => import('../views/ROOT/root-admin.vue')
			},
			{
				meta: {
					title: '用户组列表'
				},
				path: '/rootuser',
				name: 'rootuser',
				component: () => import('../views/ROOT/root-user.vue')
			},
			
			{
				meta: {
					title: '广告列表'
				},
				path: '/plugadlist',
				name: 'plugadlist',
				component: () => import('../views/PLUG/plug-ad-list.vue')
			},
			{
				meta: {
					title: '添加广告'
				},
				path: '/plugadadd',
				name: 'plugadadd',
				component: () => import('../views/PLUG/plug-ad-add.vue')
			},
			{
				meta: {
					title: '广告分类'
				},
				path: '/plugadtypelist',
				name: 'plugadtypelist',
				component: () => import('../views/PLUG/plug-adtype-list.vue')
			},

		]
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
