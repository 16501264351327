// 图片上传地址
const uploadimgurl = "http://119.53.60.154:8080/admin/common/uploadPic";// 线上配置
// lr 本地const uploadimgurl = "http://127.0.0.1:8080/admin/common/uploadPic";


// 接口路径

const apiurl = "http://119.53.60.154:8080/"// 线上配置 const
// lr  本地const apiurl = "http://127.0.0.1:8080/";


// 是否是邮箱
const isEmail = (s) => {
	return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
// 手机号码
const isMobile = (s) => {
	return /^1[0-9]{10}$/.test(s)
}
// 获取上下20年
const genumyear=(e)=> {
  var nfOptionsArray = new Array();
  var years = new Date().getFullYear();
  for (var i = years - e; i <= years + e; i++) {
    nfOptionsArray.push(i);
  }
  return nfOptionsArray;
}

// 电话号码
const isPhone = (s) => {
	return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

	// that.$utils.getBase64(file.raw).then(res => {
	// 				console.log(res, '转换的')
	// 			});
// 图片64转码
const getBase64 = (file) => {
		return new Promise(function(resolve, reject) {
			let reader = new FileReader();
			let imgResult = "";
			reader.readAsDataURL(file);
			reader.onload = function() {
				imgResult = reader.result;
			};
			reader.onerror = function(error) {
				reject(error);
			};
			reader.onloadend = function() {
				resolve(imgResult);
			};
		});
	}
	// 拆分数组
	const spArr = (arr, num) => { //arr是你要分割的数组，num是以几个为一组
		let newArr = [] //首先创建一个新的空数组。用来存放分割好的数组
		for (let i = 0; i < arr.length;) { //注意：这里与for循环不太一样的是，没有i++
			newArr.push(arr.slice(i, i += num));
		}
		return newArr
	}

// console.log(spArr(arr, 4))
//通过主键值，从列表数据里筛选出对应的一条数据
const getOne = (data, id_value, id_name) => { // data是一组列表数据，id_value是要筛选主键值，id_name是主键的键名
	let obj = {};
	data.forEach((o, i) => {
		if (id_value == o[id_name]) {
			obj = o;
		}
	});
	return obj;
}

// 图片上传
// imguploadFile = (count,url,action,file) =>
function imguploadFile(count, url, action, file, callback) {
	// count数量  url地址 action：uploadimage  file
	var imgbox = [];
	var formData = {
		token: uni.getStorageSync("token"),
		userid: uni.getStorageSync("userid"),
		action: action || "uploadimage "
	}
	uni.chooseImage({
		sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
		sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
		count: count || 1,
		success: chooseImageRes => {
			const tempFilePaths = chooseImageRes.tempFilePaths;
			for (var i = 0; i < tempFilePaths.length; i++) {
				uni.uploadFile({
					url: url,
					formData: formData,
					filePath: tempFilePaths[i],
					name: file || 'file',
					method: 'post',
					success: function(uploadFileRes) {
						console.log(JSON.parse(uploadFileRes.data), '图片');
						uploadFileRes = JSON.parse(uploadFileRes.data);
						imgbox.push(uploadFileRes.data);
						console.log(imgbox)
						callback(imgbox);
					}
				});
			}
		},

	});


}


// 是否是空
const isnull = (s) => {
	return s != '' && s != undefined && s != null && s != 'null' && s != 'undefined'
}
//邮政编码
const postal = () => {
	return /[1-9]\d{5}(?!\d)/.test(str);
}
// 随机数范围
const random = (min, max) => {
	if (arguments.length === 2) {
		return Math.floor(min + Math.random() * ((max + 1) - min))
	} else {
		return null;
	}
}

// 随机数
const getstring = (n) => {
	var str = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
		'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
	];
	var res = "";
	for (var i = 0; i < n; i++) {
		var id = Math.ceil(Math.random() * 35);
		res += str[id];
	}
	return res;
}





// 身份证
const isidcard = (s) => {
	return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(s);
}
// 解析url
const getQueryString = (name) => {
	var url = name.toString(); //当前完整url
	var u = url.split("?"); //以？为分隔符把url转换成字符串数组
	if (typeof(u[1]) == "string") {
		u = u[1].split("&"); //同上
		var get = {};
		for (var i in u) {
			var j = u[i].split("="); //同上
			get[j[0]] = j[1];
		}
		return get;
	} else {
		return {};
	}

}
// 将阿拉伯数字翻译成中文的大写数字
const numberToChinese = (num) => {
	var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
	var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
	var a = ("" + num).replace(/(^0*)/g, "").split("."),
		k = 0,
		re = "";
	for (var i = a[0].length - 1; i >= 0; i--) {
		switch (k) {
			case 0:
				re = BB[7] + re;
				break;
			case 4:
				if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$")
					.test(a[0]))
					re = BB[4] + re;
				break;
			case 8:
				re = BB[5] + re;
				BB[7] = BB[5];
				k = 0;
				break;
		}
		if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
			re = AA[0] + re;
		if (a[0].charAt(i) != 0)
			re = AA[a[0].charAt(i)] + BB[k % 4] + re;
		k++;
	}

	if (a.length > 1) // 加上小数部分(如果有小数部分)
	{
		re += BB[6];
		for (var i = 0; i < a[1].length; i++)
			re += AA[a[1].charAt(i)];
	}
	if (re == '一十')
		re = "十";
	if (re.match(/^一/) && re.length == 3)
		re = re.replace("一", "");
	return re;
}

// 将数字转换为大写金额
const changeToChinese = (Num) => {
	//判断如果传递进来的不是字符的话转换为字符
	if (typeof Num == "number") {
		Num = new String(Num);
	};
	Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
	Num = Num.replace(/ /g, "") //替换tomoney()中的空格
	Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
	if (isNaN(Num)) { //验证输入的字符是否为数字
		//alert("请检查小写金额是否正确");
		return "";
	};
	//字符处理完毕后开始转换，采用前后两部分分别转换
	var part = String(Num).split(".");
	var newchar = "";
	//小数点前进行转化
	for (var i = part[0].length - 1; i >= 0; i--) {
		if (part[0].length > 10) {
			return "";
			//若数量超过拾亿单位，提示
		}
		var tmpnewchar = ""
		var perchar = part[0].charAt(i);
		switch (perchar) {
			case "0":
				tmpnewchar = "零" + tmpnewchar;
				break;
			case "1":
				tmpnewchar = "壹" + tmpnewchar;
				break;
			case "2":
				tmpnewchar = "贰" + tmpnewchar;
				break;
			case "3":
				tmpnewchar = "叁" + tmpnewchar;
				break;
			case "4":
				tmpnewchar = "肆" + tmpnewchar;
				break;
			case "5":
				tmpnewchar = "伍" + tmpnewchar;
				break;
			case "6":
				tmpnewchar = "陆" + tmpnewchar;
				break;
			case "7":
				tmpnewchar = "柒" + tmpnewchar;
				break;
			case "8":
				tmpnewchar = "捌" + tmpnewchar;
				break;
			case "9":
				tmpnewchar = "玖" + tmpnewchar;
				break;
		}
		switch (part[0].length - i - 1) {
			case 0:
				tmpnewchar = tmpnewchar + "元";
				break;
			case 1:
				if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
				break;
			case 2:
				if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
				break;
			case 3:
				if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
				break;
			case 4:
				tmpnewchar = tmpnewchar + "万";
				break;
			case 5:
				if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
				break;
			case 6:
				if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
				break;
			case 7:
				if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
				break;
			case 8:
				tmpnewchar = tmpnewchar + "亿";
				break;
			case 9:
				tmpnewchar = tmpnewchar + "拾";
				break;
		}
		var newchar = tmpnewchar + newchar;
	}
	//小数点之后进行转化
	if (Num.indexOf(".") != -1) {
		if (part[1].length > 2) {
			// alert("小数点之后只能保留两位,系统将自动截断");
			part[1] = part[1].substr(0, 2)
		}
		for (i = 0; i < part[1].length; i++) {
			tmpnewchar = ""
			perchar = part[1].charAt(i)
			switch (perchar) {
				case "0":
					tmpnewchar = "零" + tmpnewchar;
					break;
				case "1":
					tmpnewchar = "壹" + tmpnewchar;
					break;
				case "2":
					tmpnewchar = "贰" + tmpnewchar;
					break;
				case "3":
					tmpnewchar = "叁" + tmpnewchar;
					break;
				case "4":
					tmpnewchar = "肆" + tmpnewchar;
					break;
				case "5":
					tmpnewchar = "伍" + tmpnewchar;
					break;
				case "6":
					tmpnewchar = "陆" + tmpnewchar;
					break;
				case "7":
					tmpnewchar = "柒" + tmpnewchar;
					break;
				case "8":
					tmpnewchar = "捌" + tmpnewchar;
					break;
				case "9":
					tmpnewchar = "玖" + tmpnewchar;
					break;
			}
			if (i == 0) tmpnewchar = tmpnewchar + "角";
			if (i == 1) tmpnewchar = tmpnewchar + "分";
			newchar = newchar + tmpnewchar;
		}
	}
	//替换所有无用汉字
	while (newchar.search("零零") != -1)
		newchar = newchar.replace("零零", "零");
	newchar = newchar.replace("零亿", "亿");
	newchar = newchar.replace("亿万", "亿");
	newchar = newchar.replace("零万", "万");
	newchar = newchar.replace("零元", "元");
	newchar = newchar.replace("零角", "");
	newchar = newchar.replace("零分", "");
	if (newchar.charAt(newchar.length - 1) == "元") {
		newchar = newchar + "整"
	}
	return newchar;
}

export default {
	isEmail: isEmail,
	isMobile: isMobile,
	isPhone: isPhone,
	isnull: isnull,
	getQueryString: getQueryString,
	isidcard: isidcard,
	random: random,
	numberToChinese: numberToChinese,
	changeToChinese: changeToChinese,
	uploadimgurl: uploadimgurl,
	imguploadFile: imguploadFile,
	spArr: spArr,
	getOne: getOne,
	apiurl: apiurl,
	getstring: getstring,
	getBase64:getBase64,
	genumyear:genumyear
}
